<script>
import Layout from "@/router/layouts/auth";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import Languages from "@/components/widgets/languages";
import Password from "@/components/widgets/input-password";

export default {
  locales: {
    pt: {
      'The token is invalid.': 'O token é inválido.',
      'The password recover period has expired': 'O período de recuperação da senha expirou',
      'try again': 'tente novamente',
      'Create a new password for your account.': 'Crie uma nova senha para sua conta.',
      'New Password': 'Nova Senha',
      'Confirm Password': 'Confirmar a Senha',
      'Change': 'Alterar',
      'The password recover period has expired, try again.': 'O período de recuperação da senha expirou, tente novamente.',
      'Your password has been changed successfully.': 'Sua senha foi alterada com sucesso.',
      'Remember It?': 'Lembrou-se?',
      'Sign In here': 'Entrar',
    },
    es: {
      'The token is invalid.': 'El token no es válido.',
      'The password recover period has expired': 'El período de recuperación de contraseña ha expirado',
      'try again': 'intente de nuevo',
      'Create a new password for your account.': 'Crea una nueva contraseña para tu cuenta.',
      'New Password': 'Nueva Contraseña',
      'Confirm Password': 'Confirmar Contraseña',
      'Change': 'Cambiar',
      'The password recover period has expired, try again.': 'El período de recuperación de contraseña ha expirado, intente nuevamente.',
      'Your password has been changed successfully.': 'Tu contraseña ha sido cambiada exitosamente.',
      'Remember It?': '¿Recuerdalo?',
      'Sign In here': 'Identifícate aquí',
    }
  },
  components: {
    Layout,
    Languages,
    Password
  },
  data() {
    return {
      loading: false,
      success: false,
      token: null,
      expired: false,
      newest: "",
      confirm: ""
    };
  },
  computed: {
    ...mapState({
      alert: state => state.alert
    })
  },
  validations: {
    newest: { required, minLength: minLength(6) },
    confirm: { required, minLength: minLength(6), sameAs: sameAs('newest') },
  },
  methods: {
    getRecover() {
      api
        .get('password/change/'+this.token)
        .then(response => {
          if (response.data.status=='invalid') {
            this.token = null
          } else if (response.data.status=='expired') {
            this.expired = true
          }
        })
    },
    changeSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true

        if (this.newest && this.confirm) {
          api
            .post('password/change', {
              token: this.token,
              new: this.newest,
              confirm: this.confirm,
            })
            .then(response => {
              if (response.data.status=='success') {
                this.newest = ''
                this.confirm = ''
                this.$v.$reset()

                this.alert.type = 'alert-success'
                this.alert.message = response.data.message

                this.success = true
              } else {
                this.token = 'alert-danger'
                this.alert.message = response.data.message
              }

              this.loading = false
            })
        }
      }
    }
  },
  mounted() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token
      this.getRecover();
    }
  },
};
</script>

<template>
  <Layout>
    <div id="auth" class="row m-0 flex-column-reverse flex-sm-row align-items-center">
      <div class="col">
        <div class="login-content">
          <div class="pt-5 text-center">
            <router-link class="align-middle" tag="a" to="/">
              <img class="w-100" style="max-width:250px;" src="@/assets/images/logo.png" />
            </router-link>
          </div>
          <div class="pt-5">
            <div v-if="success" class="p-4">
              {{ t(this.alert.message) }}
            </div>
            <div v-else-if="!token" class="p-4 text-center">
              {{ t('The token is invalid.') }}
            </div>
            <div v-else-if="expired" class="p-4 text-center">
              {{ t('The password recover period has expired') }}, <router-link tag="a" to="/password-forgot" class="text-success">{{ t('try again') }}.</router-link>
            </div>
            <b-form v-else class="p-3" @submit.prevent="changeSubmit">
              <div v-if="alert.message" :class="'alert ' + alert.type">{{ t(alert.message) }}</div>
              <p>{{ t('Create a new password for your account.') }}</p>
              <b-form-group id="newest" :label="t('New Password')" label-for="newest">
                <Password v-model="newest" :v="$v.newest"></Password>
              </b-form-group>
              <b-form-group id="confirm" :label="t('Confirm Password')" label-for="confirm">
                <Password v-model="confirm" :v="$v.confirm"></Password>
              </b-form-group>
              <div class="mt-4">
                <b-button :disabled="loading == true" type="submit" variant="default" class="btn-lg btn-block">
                  {{ t('Change') }}
                  <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
          <div class="mt-4 text-center">
            <p class="text-dark">
              {{ t('Remember It?') }}
              <router-link tag="a" to="/login" class="font-weight-medium text-muted">{{ t('Sign In here') }}</router-link>
            </p>
            <Languages type="dropup"></Languages>
            <hr class="mx-5">
            <p class="font-size-12 text-uppercase text-muted">
              <a class="text-muted font-size-11" target="_blank" href="https://m2n.com.br/?utm_source=americanlifecosmeticos.com.br&utm_medium=link&utm_campaign=logo">
                TECNOLOGIA POR<br>
                <img alt="M2N" style="width:50px;" src="@/assets/images/logo-m2n.svg" />
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="col login-image d-none d-lg-block"></div>
    </div>
  </Layout>
</template>
